<i18n src="../locales.json"></i18n>
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card tile flat class="pa-2" id="documents-note-text">
        <v-card-text id="please-note-verbiage">
          {{ $t('L_view_statements_for') }}
          <v-flex xs4>
            <v-select
              class="pb-0"
              v-model="viewFilter"
              :items="viewFilterItems"
            />
          </v-flex>
          {{ $t('L_please_note_that_some_documents') }}
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-card tile align-stretch flat>
        <div v-if="isCanadian" class="mutual_fund_account_css mt-4 mr-4 mb-4 ml-4">
          {{ $t('L_only_mutual_fund_account_statements_are_available_to_view_on_myprimerica_for_common_sense') }}<a href="javascript:void(0)" @click="agfLink()">www.agf.com</a>
        </div>
        <v-card flat v-if="docs.length > 0">
          <v-data-table :headers="headers" :items="docs" :hide-actions="true">
            <template v-slot:items="props">
              <td>{{ props.item.date }}</td>
              <td>{{ $t(`L_${props.item.type}`) }}</td>
              <td>
                <a @click="getDocumentPDF(props.item)">{{ props.item.statementPeriod }}</a>
              </td>
              <td>
                <v-icon @click="downloadPDF(props.item)" color="#052f60">
                  download
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card flat v-else>
          <v-card-text>
            <div class="py-5">
              <img id="documents-folder-img" height="180" width="180" src="@/assets/documentsFolder.png" alt="documents-folder" class="d-flex mx-auto">
            </div>
            <div id="you-have-no-available" class="font-weight-bold text-xs-center">
              {{ $t('L_no_documents_currently_available') }}
            </div>
          </v-card-text>
        </v-card>
      </v-card>
    </v-flex>
    <v-flex xs12 class="container" />
  </v-layout>
</template>

<script>
import { COUNTRIES } from '@/shared/constants';
import { openInNewWindow } from '@/mixins/openNewWindow';
import { mapGetters, mapActions } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { formatDate } from '@/shared/util/formatters';
import moment from 'moment';
import dayjs from 'dayjs';

export default {
  name: 'InvestmentStatements',
  components: {
    LoadingIndicator,
  },
  mixins: [openInNewWindow],
  data() {
    return {
      viewFilter: (new Date()).getFullYear(),
    };
  },
  computed: {
    ...mapGetters(['investmentStatements']),
    ...mapGetters('policy', ['base64String']),
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    ...mapGetters('customer', ['binaryInvestmentStatement', 'customerProfile']),
    isCanadian() {
      return this.customerProfile?.country === COUNTRIES.CANADA;
    },
    headers() {
      return [
        {
          text: this.$t('L_date'),
          align: 'left',
          value: 'date',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_type'),
          align: 'left',
          value: 'type',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_statement_period'),
          align: 'left',
          value: 'statementPeriod',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_download'),
          value: 'download',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
      ];
    },
    filteredInvestmentStatements() {
      const cutoffDate = dayjs(new Date(this.viewFilter, 0, 1));
      const filteredDocumentsList = this.investmentStatements.filter(((doc) => {
        const isWithinCutoff = dayjs(doc.dateReceived).isSame(cutoffDate, 'year');
        return isWithinCutoff;
      }));
      return this.viewFilter === 0 ? this.investmentStatements : filteredDocumentsList;
    },
    docs() {
      const d = this.filteredInvestmentStatements;
      const r = d && d.map((i) => {
        const startMonth = this.$t(`L_${i.name.period.start.month.toLowerCase()}`);
        const endMonth = this.$t(`L_${i.name.period.end.month.toLowerCase()}`);
        const statementPeriod = `${startMonth} ${i.name.period.start.day} - ${endMonth} ${i.name.period.end.day}, ${i.name.period.year}`;
        return {
          date: formatDate(moment(i.dateReceived, 'YYYY-MM-DD'), this.dateFormat),
          statementPeriod,
          type: i.type,
          doc: i,
        };
      });
      return r || [];
    },
    viewFilterItems() {
      const currentYear = (new Date()).getFullYear();
      const items = [{ text: currentYear.toString(), value: currentYear }];
      for (let yr = 1; yr < 7; yr++) {
        items.push({ text: (currentYear - yr), value: currentYear - yr });
      }
      items.push({ text: this.$t('L_all'), value: 0 });
      return items;
    },
  },
  methods: {
    ...mapActions('customer', ['openStatement']),
    async agfLink() {
      await this.openInNewWindow({ url: 'http://www.agf.com/', target: '_blank' });
    },
    async downloadPDF(item) {
      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replaceAll(' ', '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      // * see https://dev.to/nombrekeff/download-file-from-blob-21ho
      // Create a link pointing to the ObjectURL containing the blob
      const blobURL = await this.getInvestmentDocument(item?.doc?.metadata);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', item.statementPeriod);
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    async getDocumentPDF(item) {
      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replaceAll(' ', '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      const url = await this.getInvestmentDocument(item?.doc?.metadata);
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url, inappbrowser: !isWeb });
    },
    getInvestmentDocument(requestObject) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.openStatement(requestObject).then(() => URL.createObjectURL(new Blob([this.binaryInvestmentStatement], { type: 'application/pdf' })));
      } else {
        return this.openStatement(requestObject).then(() => this.binaryInvestmentStatement);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next((vm) => {
      // access to component public instance via `vm`
      try {
        const i = vm.$dynatrace.enterAction('view_documentsPage');
        vm.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    });
  },
  beforeRouteUpdate() {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    try {
      const i = this.$dynatrace.enterAction('view_documentsPage');
      this.$dynatrace.leaveAction(i);
    } catch (error) { /*  */ }
  },
};
</script>

<style scoped lang="stylus">
table.v-table tbody td {
  font-size: 14px !important;
  padding: 24px !important;
}
.v-datatable.v-table thead th:first-child {
  padding-left: 24px !important;
}
#documents-note-text
  border-bottom: solid 1px #ccc;
#please-note-verbiage {
  padding:16px;
}
.mutual_fund_account_css {
  font-size: 14px;
  font-weight: 500;
}
</style>
