<i18n src="../locales.json"></i18n>
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card tile flat class="pa-2" id="documents-note-text">
        <v-card-text id="please-note-verbiage">
          {{ $t('L_view_documents_for_last') }}
          <v-flex xs4>
            <v-select
              class="pb-0"
              v-model="viewFilter"
              :items="viewFilterItems"
            />
          </v-flex>
          {{ $t('L_please_note_that_some_documents') }}
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-card tile align-stretch flat>
        <v-card flat v-if="docs.length > 0">
          <v-data-table :headers="headers" :items="docs" :hide-actions="true">
            <template v-slot:items="props">
              <td>{{ props.item.date }}</td>
              <td>{{ $t(`L_${props.item.type}`) }}</td>
              <td><a @click="getDocumentPDF(props.item)">{{ props.item.name }}</a></td>
              <td>
                <v-icon @click="downloadPDF(props.item)" color="#052f60">
                  download
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card flat v-else>
          <v-card-text>
            <div class="py-5">
              <img id="documents-folder-img" height="180" width="180" src="@/assets/documentsFolder.png" alt="documents-folder" class="d-flex mx-auto">
            </div>
            <div id="you-have-no-available" class="font-weight-bold text-xs-center">
              {{ $t('L_no_documents_currently_available') }}
            </div>
          </v-card-text>
        </v-card>
      </v-card>
    </v-flex>
    <v-flex xs12 class="container" />
  </v-layout>
</template>

<script>
import { openInNewWindow } from '@/mixins/openNewWindow';
import { mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { formatDate } from '@/shared/util/formatters';
import { CapacitorFacade } from '@/plugins/capacitorPlugin';
import moment from 'moment';
import dayjs from 'dayjs';

export default {
  name: 'NoticesAndConfirmations',
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      viewFilter: 30,
    };
  },
  mixins: [openInNewWindow],
  computed: {
    ...mapGetters(['noticesAndConfirmations']),
    ...mapGetters({
      dateFormat: 'getDateFormat',
      currentLocale: 'getLocale',
    }),
    filteredNoticesAndConfirmations() {
      const cutoffDate = dayjs().subtract(+this.viewFilter, 'day');
      const filteredDocumentsList = this.noticesAndConfirmations.filter(((doc) => {
        const isWithinCutoff = dayjs(doc.dateReceived).isAfter(cutoffDate) ||
          dayjs(doc.dateReceived).isSame(cutoffDate);
        return isWithinCutoff;
      }));
      return this.viewFilter === 0 ? this.noticesAndConfirmations : filteredDocumentsList;
    },
    ...mapGetters('policy', ['base64String']),
    headers() {
      return [
        {
          text: this.$t('L_date'),
          align: 'left',
          value: 'Date',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_type'),
          align: 'left',
          value: 'Type',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_name'),
          align: 'left',
          value: 'Name',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_download'),
          value: 'Download',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
      ];
    },
    viewFilterItems() {
      return [
        { text: this.$t('L_1_month'), value: 30 },
        { text: this.$t('L_3_months'), value: 90 },
        { text: this.$t('L_6_months'), value: 180 },
        { text: this.$t('L_all'), value: 0 },
      ];
    },
    docs() {
      const d = this.filteredNoticesAndConfirmations;
      const r = d && d.map(i => ({
        date: formatDate(moment(i.dateReceived, 'YYYY-MM-DD'), this.dateFormat),
        name: `${i.labels.filter(label => label.language === this.currentLocale)[0].name} - ${i.contract}`,
        type: i.labels.filter(label => label.language === this.currentLocale)[0].type,
        doc: i,
      }));
      return r || [];
    },
  },
  methods: {
    async downloadPDF(item) {
      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replaceAll(' ', '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      // * see https://dev.to/nombrekeff/download-file-from-blob-21ho
      // Create a link pointing to the ObjectURL containing the blob
      let blobURL;
      if (item?.doc?.documentType === 'A') {
        blobURL = await this.getAllDocumentsUrl(item?.doc?.metadata);
      } else {
        blobURL = await this.getSingleDocumentUrl(item?.doc?.metadata);
      }
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', item?.name);
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    async getDocumentPDF(item) {
      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replaceAll(' ', '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      let url = '';
      if (item?.doc?.documentType === 'A') {
        url = await this.getAllDocumentsUrl(item?.doc?.metadata);
      } else {
        url = await this.getSingleDocumentUrl(item?.doc?.metadata);
      }
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url, inappbrowser: !isWeb });
    },
    // ***
    getSingleDocumentUrl(requestObject) {
      const isWeb = CapacitorFacade.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getOnePolicyDocument', requestObject)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )));
      } else {
        return this.$store.dispatch('policy/getOnePolicyDocument', requestObject).then(() => this.base64String);
      }
    },
    // ***
    getAllDocumentsUrl(requestObject) {
      const isWeb = CapacitorFacade.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )));
      } else {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject).then(() => this.base64String);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next((vm) => {
      // access to component public instance via `vm`
      try {
        const i = vm.$dynatrace.enterAction('view_documentsPage');
        vm.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    });
  },
  beforeRouteUpdate() {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    try {
      const i = this.$dynatrace.enterAction('view_documentsPage');
      this.$dynatrace.leaveAction(i);
    } catch (error) { /*  */ }
  },
};
</script>

<style scoped lang="stylus">
table.v-table tbody td {
  font-size: 14px !important;
  padding: 24px !important;
}
.v-datatable.v-table thead th:first-child {
  padding-left: 24px !important;
}
#documents-note-text
  border-bottom: solid 1px #ccc;
#please-note-verbiage {
  padding:16px;
}
</style>
