<i18n src='./locales.json'></i18n>
<template>
  <span>
    <v-layout row wrap>
      <v-flex xs12 text-xs-right>
        <locale-toggle
          :current-locale="currentLocale"
          :languages="languages"
          :display="languageFlag"
          :display-icon="true"
          @update-locale="updateLocale"
          submenu-location=""
        />
      </v-flex>
    </v-layout>
    <v-card
      id="policy_ack_card"
      class="space_to_go_under_bottom_button policy_ack_card_css"
    >
      <Alerts context="policy_ack" />
      <v-card-title class="text-xs-left" :class="$vuetify.breakpoint.xsOnly?'font-size--20': 'font-size--24'">
        <v-flex
          xs12
          pt-4
          class="font-weight-regular"
        >
          {{ $t('L_policy_acknowledgement') }}
        </v-flex>
      </v-card-title>
      <v-divider pt-4 pb-4 class="line_separator" />
      <v-card-text class="font-weight-bold pt-4 pl-4 pr-4 pb-4">
        <v-flex xs12>
          <span class="font-size--16">
            {{ $t(verbiage.byclickingbelow, { policynum: policynumber }) }}
          </span>
        </v-flex>
      </v-card-text>
      <v-card-text class="font-weight-bold pt-0 pl-4 pr-4 pb-4">
        <v-flex xs12>
          <a
            @click="showPolicy"
            class="font-size--16"
          >
            {{ $t('L_view_full_policy') }}
          </a>
        </v-flex>
      </v-card-text>
      <v-flex
        :class="{
          'ml-5 mr-5': $vuetify.breakpoint.lgAndUp
        }"
        class="paddingSpaceAroundEntireBox"
      >
        <v-card-text
          class="font-weight-regular"
          v-if="isCanadian"
        >
          <v-flex
            xs12
          >
            <span class="font-size--16">
              {{ $t('L_by_clicking_i_agree_i_acknowledge_CAN') }}
            </span>
          </v-flex>
        </v-card-text>
        <v-card-text
          class="font-weight-regular"
          v-if="verbiage.acknowledge != '' && !isCanadian"
        >
          <v-flex
            xs12
          >
            <span class="font-size--16">
              {{ $t(verbiage.acknowledge) }}
            </span>
          </v-flex>
        </v-card-text>
        <v-card-text
          class="font-weight-regular"
          v-if="verbiage.understand != '' && !isCanadian"
        >
          <v-flex
            xs12
          >
            <span class="font-size--16">
              {{ $t(verbiage.understand) }}
            </span>
          </v-flex>
        </v-card-text>
        <v-card-text v-if="!isCanadian">
          <div pl-4 pr-4 pb-0 :class="$vuetify.breakpoint.xsOnly?'iframeContentHeightMobile':'iframeContentHeightDesktop'">
            <iframe
              id="edelivery_disclosure_iframe"
              :class="$vuetify.breakpoint.xsOnly?'iframe_mobile':'iframe_desktop'"
              v-if="htmlSrc"
              :src="htmlSrc"
              title="e-delivery disclosure html"
            />
          </div>
          <div class="gradientHover pl-4 mt-2 pr-4">
            &nbsp;
          </div>
        </v-card-text>
        <v-layout row wrap>
          <v-flex
            xs12
            class="text-xs-center mt-4 pt-4"
          >
            <v-btn
              color="primary"
              @click="iAgree"
              :class="$vuetify.breakpoint.xsOnly?'fixed_bottom_button_mobile':'fixed_bottom_button_desktop'"
            >
              {{ $t('L_go_paperless_i_agree') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card>
    <EmbeddedViewer
      title="Life Policy"
      :loading="progressLoading"
      content-type="application/pdf"
      :binary-data="documentContent"
    />
  </span>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Config from '@/shared/util/config';
import { COUNTRIES } from '@/shared/constants';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';
import Alerts from '@/components/Alerts.vue';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';
import { openInNewWindow } from '@/mixins/openNewWindow';

export default {
  name: 'PolicyAcknowledgementAction',
  components: {
    Alerts,
    EmbeddedViewer,
    LocaleToggle,
  },
  mixins: [openInNewWindow],
  data: () => ({
    languages: [], // TODO: get from config and filter by location, maybe put the language Pref in store and access here
    policynumber: '',
    text: undefined,
    progressLoading: false,
    documentContent: undefined,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'policies',
      'currentPolicy',
      'base64String',
    ]),
    ...mapGetters({
      currentLocale: 'getLocale',
      locale: 'getLocale',
      containsFeature: 'containsFeature',
      tasks: 'tasks',
      getFullPolicyDocument: 'getFullPolicyDocument',
    }),
    ...mapGetters('customer', ['customerProfile']),
    languageFlag() {
      if (this.containsFeature('LANGUAGE-TOGGLE')) {
        if (this.languages?.length > 1) {
          return true;
        }
      }
      return false;
    },

    htmlSrc() {
      if (this.locale === 'en_CA') {
        return `${Config.get('ecomm.en_CA.ecommunication_consent')}`;
      } else if (this.locale === 'fr_CA') {
        return `${Config.get('ecomm.fr_CA.ecommunication_consent')}`;
      }
      return `${Config.get('ecomm.en_US.ecommunication_consent')}`;
    },
    isCanadian() {
      return this.customerProfile.country === COUNTRIES.CANADA;
    },
    verbiage() {
      let state = '';
      const BI_STATE = ['AK', 'AL', 'CA', 'CO', 'CT', 'DE', 'HI', 'IA', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MN', 'MO', 'MS',
        'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TX', 'UT', 'VT', 'WA', 'WI', 'WV'];
      const BI_STATE_NY = ['NY'];
      const NON_BI_STATE = ['AR', 'AZ', 'DC', 'FL', 'GA', 'ID', 'MI', 'TN', 'VA', 'WY'];
      if (this.currentPolicy != null && this.currentPolicy?._phases != null) {
        const phases = this.currentPolicy?._phases;
        phases.forEach((phase) => {
          // Look at the phase Issue State
          if (phase?.phaseNo === '01') {
            state = phase?.phIssueState;
          }
        });
      }
      if (state !== '') {
        if (BI_STATE_NY.find(a => a === state) !== undefined) {
          return {
            byclickingbelow: 'L_policy_ack_top_text_NY',
            acknowledge: 'L_i_also_acknowledge_NY',
            understand: 'L_i_also_understand_NY',
          };
        } else if (NON_BI_STATE.find(a => a === state) !== undefined) {
          return {
            byclickingbelow: 'L_policy_ack_top_text_Non_BI',
            acknowledge: '',
            understand: '',
          };
        } else if (BI_STATE.find(a => a === state) !== undefined) {
          return {
            byclickingbelow: 'L_policy_ack_top_text',
            acknowledge: 'L_i_also_acknowledge',
            understand: 'L_i_also_understand',
          };
        }
        return {
          byclickingbelow: 'L_policy_ack_top_text',
          acknowledge: '',
          understand: '',
        };
      } return {
        byclickingbelow: 'L_policy_ack_top_text',
        acknowledge: '',
        understand: '',
      };
    },
  },
  async mounted() {
    this.validateLanguagePreference();
    this.getPolicyAckNotShown();
    if (this.policies.length > 0 && this.policies[0].contractId === 0) {
      await this.$store.dispatch('policy/getPolicyOverview');
    }
    // This fixes the policy with POLICY_ACK being 2nd or later policy in multiple policy
    // mypriteam+be@gmail.com for testing in UAT
    const tempIndex = this.policies.findIndex(policy => policy.contractId === this.policynumber);
    this.$store.commit('policy/setSelectedPolicyIndex', tempIndex);

    const iframe = document.getElementById('edelivery_disclosure_iframe');
    iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
  },
  methods: {
    ...mapActions(['ackPolicy', 'changeLocale']),
    ...mapActions('policy', ['getOnePolicyDocument']),
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    updateLocale(language) {
      this.changeLocale(language);
    },
    validateLanguagePreference() {
      if (!this.languagesPrefs || this.languagesPrefs.length === 0) {
        this.languagePrefs = ['en_US', 'en_CA'];
      }

      if (this.customerProfile.country === COUNTRIES.CANADA) {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_CA') > 0);
        if (this.currentLocale?.indexOf('_CA') === -1) {
          this.changeLocale('en_CA');
        }
      } else {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_US') > 0);
        if (this.currentLocale?.indexOf('_US') === -1) {
          this.changeLocale('en_US');
        }
      }
    },
    getPolicyAckNotShown() {
      const idx = this.tasks.findIndex(a => a.name === 'POLICY_ACK' && a.shownFlag === false);
      if (idx !== -1) {
        this.policynumber = this.tasks[idx].policyNumbers[0];
      } else {
        this.policynumber = '';
      }
    },
    async showPolicy() {
      // MYP-6960 Policy Acknowledgement - Create custom Dynatrace tags for research and tracking purposes
      try {
        const i = this.$dynatrace.enterAction(`PC2_PolicyAcknowledgement_ViewFullPolicy_${this.policynumber}`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      /* Find the Policy Document */
      const policyDoc = this.getFullPolicyDocument(this.policynumber);

      const url = await this.getAllDocumentsUrl(policyDoc).catch((e) => {
        this.$store.commit('addError', { error: e, context: 'policy_ack' }, { root: true });
      });
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url, inappbrowser: !isWeb });
    },
    getPolicyDocFromPolicies(policyNumToGet) {
      let resultDoc = null;
      try {
        const matchingPolicyList = this.policies.filter(policy => policy._contractId.indexOf(policyNumToGet) > -1);
        for (let i = 0; i < matchingPolicyList[0]._documents.length; i++) {
          const doc = matchingPolicyList[0]._documents[i];
          if (doc.policyNum === policyNumToGet && (doc.documentType === 'A' || doc.documentType === 'S')) {
            resultDoc = doc;
          }
        }
      } catch (e) {
        try {
          // eslint-disable-next-line no-console
          console.error(`[PolicyAcknowledgementAction ~ getPolicyDocFromPolicies] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
          this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PolicyAcknowledgementAction ~ getPolicyDocFromPolicies] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
        } catch (error) {}
      }
      return resultDoc;
    },
    // ***
    getSingleDocumentUrl(policyDoc) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getOnePolicyDocument', policyDoc.metadata)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )))
          .catch((e) => {
            try {
              // eslint-disable-next-line no-console
              console.error(`[PolicyAcknowledgementAction ~ getSingleDocumentUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PolicyAcknowledgementAction ~ getSingleDocumentUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
            } catch (error) {}
          });
      } else {
        return this.$store.dispatch('policy/getOnePolicyDocument', policyDoc.metadata).then(() => this.base64String)
          .catch((e) => {
            try {
              // eslint-disable-next-line no-console
              console.error(`[PolicyAcknowledgementAction ~ getSingleDocumentUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PolicyAcknowledgementAction ~ getSingleDocumentUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
            } catch (error) {}
          });
      }
    },
    // ***
    getAllDocumentsUrl(policyDoc) {
      const requestObject = policyDoc.metadata;
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )))
          .catch((e) => {
            try {
              // eslint-disable-next-line no-console
              console.error(`[PolicyAcknowledgementAction ~ getAllDocumentsUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PolicyAcknowledgementAction ~ getAllDocumentsUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
            } catch (error) {}
          });
      } else {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject).then(() => this.base64String)
          .catch((e) => {
            try {
              // eslint-disable-next-line no-console
              console.error(`[PolicyAcknowledgementAction ~ getAllDocumentsUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PolicyAcknowledgementAction ~ getAllDocumentsUrl] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
            } catch (error) {}
          });
      }
    },
    async iAgree() {
      // MYP-6960 Policy Acknowledgement - Create custom Dynatrace tags for research and tracking purposes
      try {
        const i = this.$dynatrace.enterAction(`PC2_PolicyAcknowledgement_IAgree_${this.policynumber}`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      try {
        this.removeAlerts('policy_ack');
        const data = {
          policyNum: this.policynumber,
          locale: this.locale,
        };
        this.ackPolicy(data);
        this.setShownFlag(this.policynumber);
        const deeplinkroute = window.localStorage.getItem('deeplinkroute'); // ? this is a path, NOT name of a route
        if (deeplinkroute) window.localStorage.removeItem('deeplinkroute'); // remove the deeplink route
        await this.$router.push((deeplinkroute ? { path: deeplinkroute } : { name: 'Dashboard' }));
      } catch (e) {
        try {
          // eslint-disable-next-line no-console
          console.error(`[PolicyAcknowledgementAction ~ iAgree] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
          this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[PolicyAcknowledgementAction ~ iAgree] ${e?.code}: ${e?.message || JSON.stringify(e)}`);
        } catch (error) {}
        await this.$router.push({ name: 'Dashboard' }); // route to dashboard
      }
    },
    setShownFlag(policyNum) {
      // To find a specific object in an array of objects
      const idx = this.tasks.findIndex(a => a.name === 'POLICY_ACK' && a.policyNumbers[0] === policyNum);
      if (idx !== -1) {
        this.tasks[idx].shownFlag = true;
      }
    },
  },
};
</script>

<style>
.line_separator {
  border: 1px solid #bcbaba;
}
.policy_ack_card_css {
  max-width: 960px;
  margin: auto;
}
.bg-light-grey {
  background-color: #f5f5f5;
}
.paddingSpaceAroundEntireBox {
  padding: 0 40px 0px 40px;
}
.iframe_mobile {
  width: 100%;
  border: 2px solid black;
  height: calc(100vh - 370px) !important;
}
.iframe_desktop {
  width: 100%;
  border: 2px solid black;
  height: calc(100vh - 385px);
}
.iframeContentHeightMobile{
  height: calc(100vh - 370px);
}
.iframeContentHeightDesktop{
  height: calc(100vh - 385px);
}
.fixed_bottom_button_mobile {
  bottom: 10px;
  width: 280px !important;
  margin: auto !important;
}
.fixed_bottom_button_desktop {
  bottom: 10px;
  width: 280px !important;
  margin: auto !important;
}
.space_to_go_under_bottom_button {
  padding-bottom: 150px;
}
.zgradientHover {
    position: relative;
    bottom: 51px;
    left: 0;
    background: linear-gradient(rgba);
    width: 100%;
    height: 50px;
    background: -webkit-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
    background-image: -moz-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
    background-image: -o-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
    background-image: linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
}
</style>
