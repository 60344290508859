import moment from 'moment';
import { getClientCommunicationsPhone } from '@/shared/data/mappers';

export const step = state => state.step;

export const scrollToPolicyDetails = state => state.scrollToPolicyDetails;

export const hasTerminatedPolicies = state => state.hasTerminatedPolicies;

export const terminatedPolicyAgent = state => state.terminatedPolicyAgent;

export const repSort = (a, b) => b.products.length - a.products.length;

export const currentPolicy = state => (state.policies.length ? state.policies[state.selectedPolicyIndex] : null);

export const issueDateForCurrentPolicy = (state, getters) => {
  const policy = getters.currentPolicy;
  if (policy.phases !== undefined && policy.phases.length > 0) {
    const phaseForIssueDateThatWeWant = policy.phases.filter(phase => phase.phPlanzPerson === '20');
    if (phaseForIssueDateThatWeWant.length > 0) {
      return phaseForIssueDateThatWeWant[0].phIssueDate;
    }
    return '';
  }
  return '';
};

export const panelValue = (state, getters) => getters.currentPaymentPanel.panelValue;

export const contactDetailsPanel = state => state.contactDetailsPanel;

export const editContactInfo = state => state.editContactInfo;

export const policyOwner = (state, getters) => getters.currentPolicy?.owner;

export const activePrimaryCoverage = state => state.policies
  .reduce((accumulator, policy) => accumulator + policy.activePrimaryCoverage, 0);

export const activeSpouseCoverage = state => state.policies
  .reduce((accumulator, policy) => accumulator + policy.activeSpouseCoverage, 0);

export const isPending = (state, getters) => {
  const policy = getters.currentPolicy;
  return policy?.pending;
};

export const isRestricted = (state, getters) => !getters.currentPolicy?.data || getters.currentPolicy?.restricted;

export const isHomeOffice = (state, getters, rootGetters) => {
  const policyData = getters.currentPolicy;
  const agent = rootGetters.getAgent(policyData?.agentId);
  return !getters.isRestricted && agent?.isHomeOfficeAgent;
};

export const restrictCodeMessage = (state, getters) => {
  // For policies with the following restrict codes, display the messages as indicated - see restrictions below
  //  1S
  //  1W
  //  1A
  //    No premiums are due or being collected, policy is on Waiver of Premium. For additional assistance or information,
  //    please contact our Claims department at >number varies depending on if US/NY/CAN<.
  //  0P
  //  1P
  //  0A
  //    This policy has a TIAB Claim in Progress, for additional assistance or information,
  //    please contact our Claims department at >number varies depending on if US/NY/CAN<.
  //  0H
  //    No message
  const policy = getters.currentPolicy;
  if (policy) {
    switch (policy.restrictCode) {
      // case '1W':
      // case '1S':
      // case '1A':
      //   return 'L_restrict_code_message_WOP';
      // case '0P':
      // case '1P':
      // case '0A':
      //  return 'L_restrict_code_message_TIAB';
      case '0H':
      default:
        return '';
    }
  }
  return '';
};

export const clientCommunicationsPhone = (state, getters) => {
  // number varies depending on if US/NY/CAN
  const policy = getters.currentPolicy;
  return getClientCommunicationsPhone(policy);
};

export const successResponseUpdateContactInfo = state => state.successResponseUpdateContactInfo;

export const errorResponseUpdateContactInfo = state => state.errorResponseUpdateContactInfo;

export const addressVerification = state => state.addressVerification;

export const addressUpdateSuccess = state => state.addressUpdateSuccess;

export const navHintDismissed = state => state.navHintDismissed;

export const policies = state => state.policies;

export const selectedPolicyIndex = state => state.selectedPolicyIndex;

export const policyCount = state => (state.policies && state.policies.length) || 0;

export const base64String = state => state.base64String;

export const bankNameResponse = state => state?.bankName;

export const paymentData = state => state?.paymentData;

export const paymentInfoLoading = state => state?.loading.payment;

export const achSubmissionLoading = state => state?.loading.ach;

export const policyInfoLoading = state => state?.loading.policy;

export const beneficiariesLoading = state => state?.loading.beneficiaries;

export const getCustomerProfile = state => state.customerProfile;

export const currentPaymentPanel = state => state.paymentPanel[state.selectedPolicyIndex];

export const getPaymentMethodChangeAlert = (state, getters) => getters.currentPaymentPanel.editPayments.paymentMethodChangeAlert;

export const getEditPaymentMethod = (state, getters) => getters.currentPaymentPanel.editPayments.editPaymentMethod;

export const getDisablePaymentDetailsLink = (state, getters) => getters.currentPaymentPanel.editPayments.disablePaymentDetailsLink;

export const getshowPayNowInfoCard = state => state.showPayNowInfoCard;

export const getUpdatingBank = state => state.updatingBank;

export const getPaymentInfoShowPaymentAmountDateBillingMethod = state => state.paymentInfoShowPaymentAmountDateBillingMethod;

// TODO: Unit Test
export const showPayNow = (state, getters, rootState, rootGetters) => {
  const policy = getters.currentPolicy;
  return rootGetters?.containsFeature('PAY-ONETIME') && state.payNowLinkToggle &&
  // return policy?.showPayNowLink && rootState?.features?.has('pc2.pay.onetime') && state.payNowLinkToggle &&
    !(!!policy?._kubraPaymentStatus || policy?._paymentInfo?._payInProcess === '1') && policy?.showPayNowLink;
};

export const showPayNowFeatureFlagAndPayNowLinkToggle = (state, getters, rootState, rootGetters) => rootGetters?.containsFeature('PAY-ONETIME') && state.payNowLinkToggle;

export const policiesSortedByDate = ((state) => {
  const pols = [];
  state.policies.forEach((policy) => {
    pols.push(policy);
  });
  pols.sort((a, b) => {
    const aDate = moment(a.data.issueDate, 'YYYY-MM-DD');
    const bDate = moment(b.data.issueDate, 'YYYY-MM-DD');
    return bDate - aDate;
  });
  return pols;
});

export const beneficiaries = state => state.beneficiaries;

export const beneficiaryStep = state => state.beneficiaryStep;

export const beneficiaryPrimary = state => state.beneficiaryPrimary;

export const beneficiaryPrimaryContingent = state => state.beneficiaryPrimaryContingent;

export const beneficiarySpouse = state => state.beneficiarySpouse;

export const beneficiarySpouseContingent = state => state.beneficiarySpouseContingent;

export const beneficiariesTotalPrimaryPrimary = state => state.beneficiaryPrimary.reduce((sum, bene) => sum + parseInt(bene.beneficiaryPercent || 0, 10), 0);

export const beneficiariesTotalPrimaryContingent = state => state.beneficiaryPrimaryContingent.reduce((sum, bene) => sum + parseInt(bene.beneficiaryPercent || 0, 10), 0);

export const beneficiariesTotalSpousePrimary = state => state.beneficiarySpouse.reduce((sum, bene) => sum + parseInt(bene.beneficiaryPercent || 0, 10), 0);

export const beneficiariesTotalSpouseContingent = state => state.beneficiarySpouseContingent.reduce((sum, bene) => sum + parseInt(bene.beneficiaryPercent || 0, 10), 0);

export const showSpouseSection = state => state.beneficiaries.filter(obj => obj.beneficiaryType === 'S').length > 0;

export const fetchDataForVAndTPrimaryPrimary = state => state.beneficiaries.filter(obj => obj.beneficiaryType === 'P' && (obj.origin === 'V' || obj.origin === 'T'));

export const fetchDataForVAndTPrimaryContingent = state => state.beneficiaries.filter(obj => obj.beneficiaryType === 'C' && (obj.origin === 'V' || obj.origin === 'T'));

export const fetchDataForVAndTSpousePrimary = state => state.beneficiaries.filter(obj => obj.beneficiaryType === 'S' && (obj.origin === 'V' || obj.origin === 'T'));
export const fetchDataForVAndTSpouseContingent = state => state.beneficiaries.filter(obj => obj.beneficiaryType === 'H' && (obj.origin === 'V' || obj.origin === 'T'));

export const beneficiaryHasOriginVorTWithIrrevocable = state => state.beneficiaries.filter(obj => obj.irrevocable === true && (obj.origin === 'V' || obj.origin === 'T')).length > 0;

export const beneficiaryHasOriginVorT = state => state.beneficiaries.filter(obj => obj.origin === 'V' || obj.origin === 'T').length > 0;

export const isBeneficiaryDataDirty = state => state.isBeneficiaryDataDirty;

export const paymentStep = state => state.paymentStep;

export const routeNameToNavigateBack = state => state.routeNameToNavigateBack;

export const withDrawalDateStep = state => state.withDrawalDateStep;
