<i18n src='../../locales.json'></i18n>

<template>
  <v-flex
    xs12
    id="policy-overview"
  >
    <v-card>
      <Alert
        :show-alert="policyData.billingForm === 'H1'"
        :dismissable="false"
        :auto-dismiss="false"
        alert-icon="warning"
        type="error"
      >
        <span slot="message">
          {{ $t('L_your_policy_will_be_terminated',{date: formattedPaymentDueDate }) }}
        </span>
      </Alert>
      <v-card-title>
        <v-flex>
          <span
            role="heading"
            aria-level="2"
            class="card-title"
          >{{ __t('L_policy_policy_overview') }}</span>
        </v-flex>
        <v-spacer />
        <v-flex shrink>
          <span v-if="hasFullPolicyDocuments">
            <a
              href="javascript:void(0)"
              @click="openDocumentInNewWindow"
              class="subheading hidden-xs-only font-weight-medium"
              id="view_full_policy_details"
            >{{ $t('L_policy_view_full_policy') }}</a>
            <span
              class="grey--text text--lighten-1 ml-2 mr-2 hidden-xs-only"
            >|</span>
          </span>
          <span
            class="subheading font-weight-medium hidden-xs-only"
          >{{ $t('L_policy_status') }}</span>
          <span
            class="title font-weight-regular"
            :aria-label="!policyData.pending ? 'Policy Active' : 'Policy Pending'"
          >
            <v-icon
              :color="policyStatusIconColor"
              class="ml-2 mr-2"
            >{{ policyStatusIcon }}</v-icon>
            <span
              class="hidden-xs-only"
              aria-hidden="true"
            >{{ policyStatusDescription }}</span>
          </span>
        </v-flex>
      </v-card-title>
      <ChildRiderAlert
        v-if="hasCRCPackageForCurrentPolicy"
      />
      <Alert
        :show-alert="addChildSuccess"
        :dismissable="true"
        :auto-dismiss="false"
        alert-icon="check_circle"
        type="success"
        @close-alert="addChildSuccess = false"
      >
        <span slot="message">
          {{ $t('L_add_child_success') }}
        </span>
      </Alert>
      <Alert
        :show-alert="showAlert"
        :dismissable="false"
        :type="'info'"
      >
        <span slot="message">
          {{ $t(restrictCodeMessage) }} <a :href="'tel:+'+clientCommunicationsPhone">{{ clientCommunicationsPhone }}</a>.
        </span>
      </Alert>

      <v-card-text>
        <v-flex class="dim">
          <v-layout
            v-if="policyData.active"
            class="hidden-sm-and-up text-xs-center"
          >
            <v-flex>
              <a
                v-if="hasFullPolicyDocuments"
                href="javascript:void(0)"
                @click="openDocumentInNewWindow"
                class="subheading font-weight-medium"
              >{{ $t('L_policy_view_full_policy') }}</a>
            </v-flex>
          </v-layout>

          <!-- Insured Names -->
          <v-layout row class="wrap-at-xs overview-row">
            <!-- Primary Name -->
            <v-flex xs12 md12 sm12 lg12>
              <PolicyDetailsInfo class="overview-info">
                <template slot="title">
                  <v-layout row wrap justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ $t('L_policy_primary_insured') }}
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ __t('L_policy_total_coverage_amount') }}
                      <v-icon
                        tabindex="0"
                        color="#02579E"
                        small
                        class="pri-inline-icon pl-1"
                        size="18"
                        style="margin-bottom: -3px;"
                        aria-label="coverage amount information"
                        @blur="changeStatus"
                        @focus="changeStatus"
                      >
                        help
                      </v-icon>
                      <v-tooltip
                        slot="append"
                        content-class="text-xs-center"
                        nudge-left="60"
                        right
                        :nudge-bottom="this.$vuetify.breakpoint.mdAndUp ? '50': '70'"
                        :max-width="this.$vuetify.breakpoint.mdAndUp ? '194' : '162'"
                        v-model="status"
                        color="primary"
                      >
                        <v-icon slot="activator" />
                        <span
                          role="tooltip"
                          aria-live="polite"
                          id="policy_number_tooltip"
                        >
                          {{ __t('L_coverage_amount_tooltip') }}
                        </span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </template>
                <template slot="content">
                  <v-layout row wrap justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ policyData.insuredName }}
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ formattedPrimaryCoverage }}
                    </v-flex>
                  </v-layout>
                </template>
              </PolicyDetailsInfo>
            </v-flex>
          </v-layout>
          <v-layout row class="wrap-at-xs overview-row" v-if="spouseName">
            <!-- spouse name -->
            <v-flex xs12 md12 sm12 lg12>
              <PolicyDetailsInfo class="overview-info">
                <template slot="title">
                  <v-layout row wrap justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ $t('L_policy_spouse_insured') }}
                    </v-flex>
                    <v-spacer />
                  </v-layout>
                </template>
                <template slot="content">
                  <v-layout row wrap justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ spouseName }}
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ formattedSpouseCoverage }}
                    </v-flex>
                  </v-layout>
                </template>
              </PolicyDetailsInfo>
            </v-flex>
          </v-layout>
          <v-layout row class="wrap-at-xs overview-row" v-if="hasChildren">
            <!-- childrens name -->
            <v-flex xs12 md12 sm12 lg12>
              <PolicyDetailsInfo class="overview-info">
                <template slot="title">
                  <v-layout row wrap justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                      {{ $t('L_policy_children_insured') }}
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6 class="title font-weight-regular">
                      {{ formattedChildrenCoverage }}
                    </v-flex>
                    <v-spacer />
                  </v-layout>
                </template>
                <template slot="content">
                  <v-layout row wrap justify-center v-for="(name, index) in childrenNames" :key="index">
                    <v-flex xs6 sm6 md6 lg6>
                      {{ name }}
                    </v-flex>
                    <v-spacer />
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                      <div style="padding-top:20px" v-feature:pc2-pol-childadd>
                        <a
                          id="add_child_trigger"
                          href="javascript:void(0);"
                          :class="{
                            'ml-2 pl-1': $vuetify.breakpoint.smAndDown,
                          }"
                          @click="addChildOpen = true"
                          class="d-inline-block"
                        >
                          <v-layout
                            shrink
                            v-if="policy.showAddChildLink"
                            align-center
                          >
                            <v-icon class="pri-inline-icon pl-1 cursor-pointer" color="#02579E" size="x-large" aria-label="Add Child">
                              add_circle
                            </v-icon>
                            <span class="css_add_a_child font-size--16 font-weight-bold pl-1" color="#02579E">
                              {{ $t('L_add_a_child') }}
                            </span>
                          </v-layout>
                        </a>
                      </div>
                    </v-flex>
                    <v-spacer />
                  </v-layout>
                </template>
              </PolicyDetailsInfo>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
      <!-- TODO: remove @getDocument and @getAllDocuments if unused -->
      <PolicyDetailsPanel
        :coverage-amount="formattedCoverageAmount"
        :progress-loading="progressLoading"
        :policy-data="policyData"
        @getAllDocuments="openDocumentInNewWindow"
      />
      <EmbeddedViewer
        title="Life Policy"
        :loading="progressLoading"
        content-type="application/pdf"
        :binary-data="documentContent"
      />
    </v-card>
    <Dialog
      v-bind="dialogProps"
      @close-dialog="dialogProps.dialogOpen = false"
    />
    <v-dialog
      v-model="addChildOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @close="addChildOpen = false"
      max-width="1080"
    >
      <AddChild
        @close="addChildOpen = false, addChildSuccess = true"
        @cancel="addChildOpen = false, addChildSuccess = false"
      />
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { formatDate, titleCase, currency } from '@/shared/util/formatters';
import Dialog from '@/components/Dialog/Dialog.vue';
import Alert from '@/components/Alert/Alert.vue';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';
import { openInNewWindow } from '@/mixins/openNewWindow';
import PolicyDetailsInfo from '../../PolicyDetailsInfo.vue';
import PolicyDetailsPanel from './components/PolicyDetailsPanel/PolicyDetailsPanel.vue';
import __t from '../../translate';
import ChildRiderAlert from '../../../../childrider/components/ChildRiderAlert/ChildRiderAlert.vue';
import AddChild from '../AddChild/AddChild.vue';

export function addAnotherChild() {
  this.addChildOpen = true;
}

export default {
  name: 'PolicyOverview',
  components: {
    PolicyDetailsInfo,
    PolicyDetailsPanel,
    Alert,
    ChildRiderAlert,
    EmbeddedViewer,
    Dialog,
    AddChild,
  },
  mixins: [openInNewWindow],
  props: {
    policyData: {
      type: Object,
      required: true,
    },
    restrictCodeMessage: {
      type: String,
      required: false,
      default: '',
    },
    clientCommunicationsPhone: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    status: false,
    addChildSuccess: false,
    progressLoading: false,
    documentContent: null,
    dialogProps: {
      dialogOpen: false,
      content: 'content',
      title: 'title',
    },
    addChildOpen: false,
  }),
  computed: {
    ...mapGetters('policy', {
      base64String: 'base64String',
      policy: 'currentPolicy',
    }),
    ...mapGetters({
      getFullPolicyDocument: 'getFullPolicyDocument',
    }),
    ...mapGetters('childrider', {
      hasPackageGivenPolicyId: 'hasPackageGivenPolicyId',
    }),
    policyStatusIconColor() {
      // return 'error';
      let color = '';
      if (this.policyData.terminated) {
        color = 'error';
      } else if (this.policyData.pending) {
        color = '#ee7026';
      } else {
        color = '#0b8d1f';
      }
      return color;
    },
    policyStatusIcon() {
      let icon = 'check_circle';
      if (this.policyData.terminated) {
        icon = 'error';
      } else if (this.policyData.pending) {
        icon = 'access_time';
      } else {
        icon = 'check_circle';
      }
      return icon;
    },
    policyStatusDescription() {
      let description = '';
      if (this.policyData.terminated) {
        description = this.$t('L_terminated');
      } else if (this.policyData.pending) {
        description = this.$t('L_pending');
      } else {
        description = this.$t('L_active');
      }
      return description;
    },
    hasCRCPackageForCurrentPolicy() {
      return this.hasPackageGivenPolicyId(this.policyData.contractId);
    },
    hasFullPolicyDocuments() {
      if (this.fullPolicyDocument) {
        return true;
      }
      return false;
    },
    fullPolicyDocument() {
      return this.getFullPolicyDocument(this.policyData.contractId);
    },
    policyStatus() {
      return (this.policyData.details) ? titleCase(this.policyData.details.status) : '';
    },
    formattedCoverageAmount() {
      return currency(this.policyData.totalCoverage, 0);
    },
    showAlert() {
      return this.restrictCodeMessage ? this.restrictCodeMessage.length > 0 : false;
    },
    formattedPrimaryCoverage() {
      return currency(this.policyData.primaryCoverage, 0);
    },
    formattedSpouseCoverage() {
      return currency(this.policyData.spouseCoverage, 0);
    },
    formattedChildrenCoverage() {
      return currency(this.policyData.childCoverage, 0);
    },
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
    isNewYorkPolicy() {
      return this.policyData?.isNewYorkPolicy;
    },
    formattedPaymentDueDate() {
      return formatDate(this.policyData?.nextPaymentDueDate, 'll');
    },
    spouseName() {
      if (this.policyData?.data?.spouseFirstName) {
        return `${this.policyData?.data?.spouseFirstName} ${this.policyData?.data?.spouseLastName}`;
      }
      return '';
    },
    hasChildren() {
      if (this.policyData?.data?.children && this.policyData?.data?.children.length > 0) {
        return true;
      }
      return false;
    },
    childrenNames() {
      return this.policyData?.data?.children;
    },
  },
  methods: {
    ...mapMutations(['setRootLoading']),
    __t,
    async openDocumentInNewWindow() {
      const url = await this.getAllDocumentsUrl();
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url, inappbrowser: !isWeb });
    },
    changeStatus() {
      this.status = !this.status;
    },
    isAddAChildren() {
      if (this?.step === 50) {
        this.addChildOpen = true;
      }
    },
    async getAllDocumentsUrl() {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getAllPolicyDocuments', this.fullPolicyDocument.metadata)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )));
      } else {
        return this.$store.dispatch('policy/getAllPolicyDocuments', this.fullPolicyDocument.metadata).then(() => this.base64String);
      }
    },
  },
  mounted() {
    this.$eventBus.$on('add-another-child', addAnotherChild.bind(this));
    this.isAddAChildren();
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

.overview-row
  .overview-info
    margin-top: 32px;
  @media $display-breakpoints.sm-and-up
      margin-bottom: $spacers.three.y;
      margin-top: $spacers.three.y;
      .overview-info
        margin-top: 0;
.text-decoration--underline
  text-decoration underline
</style>
