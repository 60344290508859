import { render, staticRenderFns } from "./PolicyOverview.vue?vue&type=template&id=3437e722&scoped=true"
import script from "./PolicyOverview.vue?vue&type=script&lang=js"
export * from "./PolicyOverview.vue?vue&type=script&lang=js"
import style0 from "./PolicyOverview.vue?vue&type=style&index=0&id=3437e722&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3437e722",
  null
  
)

/* custom blocks */
import block0 from "../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPolicyOverview%2FPolicyOverview.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports