/**
 * See http://confluence.primerica.com/display/ADstandard/MyPri+Child+Rider+Conversion+Frontend
 */

import bff from '@/shared/api/bff';
import content from '@/shared/api/content';
import { CapacitorFacade } from '@/plugins/capacitorPlugin';
import * as MUTATION from './mutation-types';

// CRC steps or application state
const STEP = {
  READY: 0,
  A_PERSONAL_INFO: 1,
  B_SELECTION_OPTIONS: 2,
  C_CONFIRM_PAYMENT: 3,
  D_EDELIVERY: 4,
  E_ACKNOWLEDGE: 5,
  F_SUCCESS: 6,
};

// MAIN ACTIONS - changes steps or application state

export const crcInit = async ({ dispatch, commit }) => {
  // pull list of package summaries from BFF
  await dispatch('pullPackages');

  // go to `READY` step
  commit(MUTATION.STEP, STEP.READY);
};

export const crcStart = async ({
  dispatch, commit, getters, rootGetters,
}) => {
  // pull package details from BFF
  await dispatch('pullPackage');
  // Clear out data from previous
  await dispatch('resetCRCData');
  // set new policy package info
  const curentPolicyId = rootGetters['policy/currentPolicy']._contractId;
  const _package = getters.packagesGivenPolicyId(curentPolicyId)[0];
  const npPackage = (({ policyId, packageId, paymentMode }) => ({ policyId, packageId, paymentMode }))(_package);
  commit(MUTATION.NEW_POLICY, npPackage);

  // set new policy personal info
  if (_package.primary && _package.primary.DOB) {
    commit(MUTATION.NEW_POLICY, _package.primary);
  }

  await dispatch('pullAgreement', 'D');

  // set acknowledgement
  await dispatch('pullAgreement', 'C');

  // set Expiration Date
  commit(MUTATION.EXPDATE, getters.expireDate);

  // go to `A_PERSONAL_INFO` step
  commit(MUTATION.STEP, STEP.A_PERSONAL_INFO);
};

export const crcNext = ({ commit, getters }) => {
  // go to next step
  commit(MUTATION.STEP, getters.step + 1);
};

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const crcSubmit = async ({ commit, dispatch }) => {
  // push package update (new policy from conversion)
  await dispatch('pushPackage');

  // allow server to finish process
  await timeout(10000);

  // pull latest from server
  await dispatch('pullPackages');

  // go to `F_SUCCESS` step
  commit(MUTATION.STEP, STEP.F_SUCCESS);
};

export const resetCRCData = ({ commit }) => {
  // clear data
  commit(MUTATION.NEW_POLICY, {
    packageIndex: -1,
    policyId: '', // parent policy id
    packageId: '',
    newPolicyId: '', // submitted policy id (temporary)
    firstName: '', // retrieved from life-policies-sys primInsFirstName; new policy - primary first name; goes to NB_COSMO_CRC
    lastName: '', // retrieved from life-policies-sys primInsLastName; new policy - primary middle name; goes to NB_COSMO_CRC
    middleName: '', // retrieved from life-policies-sys primInsMiddleName; new policy - primary last name; goes to NB_COSMO_CRC
    DOB: '', // retrieved from life-policies-sys primInsDob; new policy - primary dob; goes to NB_COSMO_CRC
    gender: '', // new policy - gender; goes to NB_COSMO_CRC
    SSN: '', // new policy - SSN; goes to NB_COSMO_CRC
    selectedOption: '',
    eDelivery: {
      agreementId: -1,
      agreementLink: '',
      agreementType: 'D',
      createTs: '',
      optIn: '', // goes to NB_COSMO_CRC E_DELIVERY ('Y' or 'N')
    },
    acknowledgement: {
      agreementId: -1,
      agreementLink: '',
      agreementType: 'C',
      createTs: '',
    },
    requestNumber: '',
  });
};

export const crcCancel = async ({ commit, dispatch }) => {
  // Clear out data from previous
  await dispatch('resetCRCData');
  // go back to `READY` step
  commit(MUTATION.STEP, STEP.READY);
};

// END MAIN ACTIONS

// HELPER ACTIONS - BFF or other API Integration

export const pullPackages = async ({ commit }) => {
  const response = await bff.pullPackages();
  if (response.status === 200) {
    commit(MUTATION.PACKAGES, response.data);
  } else {
    throw new Error('Pulling CRC packages failed.');
  }
};

export const pullPackage = async ({ commit, getters, rootGetters }) => {
  const curentPolicyId = rootGetters['policy/currentPolicy']._contractId;
  const { packageId } = getters.packagesGivenPolicyId(curentPolicyId)[0];
  const response = await bff.pullPackage(curentPolicyId, packageId);
  if (response.status === 200) {
    commit(MUTATION.PACKAGE, response.data);
    commit(MUTATION.LRCIND, response.data?.lrcInd);
  } else {
    throw new Error('Pulling CRC package failed.');
  }
};

function isNextGen(rootGetters) {
  /*
  Identifying NextGen CRC packets (see https://pri-jira.atlassian.net/browse/MYP-2896):
  Per LifeOps team (Chris Thompson), there will not be a NextGen indicator added to the current CRC service / api
  to denote which policies are NextGen versus pre-NextGen. Instead, NextGen CRC packets will not have tobacco rates.
  The following CRC_EVENT_LOG table data fields should contain zero (0 or 0.00) values:
  OPT1_T_PREM, OPT2_T_PREM, OPT3_T_PREM, OPT4_T_PREM, OPT5_T_PREM */
  const selectedPackage = rootGetters['childrider/NPPackage'];
  const options = Object.entries(selectedPackage.selectionOptions ? selectedPackage.selectionOptions : []);
  const tobaccoRates = [];
  if (!options.length) {
    return false;
  }
  // filter out the tobacco rates
  options.forEach(([key, value]) => {
    if (key.indexOf('T') !== -1) {
      tobaccoRates.push(Number(value.premiumAmount));
    }
  });
  // check if packets does not have tobacco rates (premium amounts have 0 or 0.00 values)
  return tobaccoRates.every(e => e <= 0);
}

export const pushPackage = async ({ commit, rootGetters, getters }) => {
  // set request body
  const primary = (({
    firstName, middleName, lastName, gender, SSN,
  }) => ({
    firstName, middleName, lastName, gender, SSN,
  }))(getters.newPolicy);
  const eDelivery = (({ optIn, agreementId, agreementType }) => ({ optIn, agreementId, agreementType }))(getters.newPolicy.eDelivery);
  const acknowledgement = (({ agreementId, agreementType }) => ({ agreementId, agreementType }))(getters.newPolicy.acknowledgement);
  const request = {
    primary,
  };
  request.eDelivery = eDelivery;
  request.acknowledgement = acknowledgement;
  request.selectedOption = getters.newPolicy.selectedOption;
  request.policyId = getters.newPolicy.policyId;
  request.packageId = getters.newPolicy.packageId;
  request.isNextgenPolicy = isNextGen(rootGetters);
  const response = await bff.updatePackage(request);
  // throw error in case of non-200 response
  if (Math.floor(response.status / 100) !== 2) {
    throw new Error('Pushing CRC package update failed.');
  }

  const requestNumber = response.data.newPolicyNumber;
  commit(MUTATION.NEW_POLICY, { requestNumber });
};

export const pullAgreement = async ({ commit, rootGetters }, type) => {
  const policyNumber = rootGetters['policy/currentPolicy']._contractId;
  const res = await bff.getAgreement(type, rootGetters.getLocale, policyNumber);
  if (res?.status === 200 && res?.data?.agreementId && type === 'D') {
    commit(MUTATION.NEW_POLICY_EDELIVERY, res.data);
  } else if (res?.status === 200 && res?.data?.agreementId && type === 'C') {
    commit(MUTATION.NEW_POLICY_ACKNOWLEDGEMENT, res.data);
  }
};
// ***
// calls content manager
// TODO: consider renaming based on return value
export const openPDFPackage = async ({ rootGetters }, objectCode) => {
  const policyNumber = rootGetters['policy/currentPolicy']._contractId;
  const docs = rootGetters.getDocuments(policyNumber);
  const doc = docs.find(a => a.metadata.objectCode === objectCode);
  const isWeb = CapacitorFacade.isWeb();
  let pdfURL;
  if (isWeb) {
    // download binary
    const pdfPackageBinary = await content.streamDocument(doc.metadata);
    //  create a url to show binary content
    pdfURL = URL.createObjectURL(new Blob(
      [pdfPackageBinary],
      { type: 'application/pdf' },
    ));
  } else {
    pdfURL = await content.streamDocument(doc.metadata);
  }
  return pdfURL;
};
// END HELPER ACTIONS - BFF Integration

// HELPER ACTIONS - Form Inputs

export const setNPFirstName = ({ commit }, firstName) => {
  commit(MUTATION.NEW_POLICY, { firstName });
};

export const setNPMiddleName = ({ commit }, middleName) => {
  commit(MUTATION.NEW_POLICY, { middleName });
};

export const setNPLastName = ({ commit }, lastName) => {
  commit(MUTATION.NEW_POLICY, { lastName });
};

export const setNPGender = ({ commit }, gender) => {
  commit(MUTATION.NEW_POLICY, { gender });
};

export const setNPDOB = ({ commit }, DOB) => {
  commit(MUTATION.NEW_POLICY, { DOB });
};

export const setNPSSN = ({ commit }, SSN) => {
  commit(MUTATION.NEW_POLICY, { SSN });
};

export const setNPSelectedOption = ({ commit }, selectedOption) => {
  commit(MUTATION.NEW_POLICY, { selectedOption });
};

export const setNPSelectedFaceAmount = ({ commit }, selectedFaceAmount) => {
  commit(MUTATION.NEW_POLICY, { selectedFaceAmount });
};

export const setNPSelectedPremiumAmount = ({ commit }, selectedPremiumAmount) => {
  commit(MUTATION.NEW_POLICY, { selectedPremiumAmount });
};

export const setNPEDeliveryOptIn = ({ commit }, optIn) => {
  commit(MUTATION.NEW_POLICY_EDELIVERY, { optIn });
};

export const setLoading = ({ commit }, loading) => {
  commit(MUTATION.LOADING, loading);
};

// END HELPER ACTIONS - Form Inputs
